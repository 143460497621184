/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; /* Base font size */
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
}

/* App Container */
.App {
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem; /* 10px */
  width: 100%;
  max-width: 50rem; /* 800px */
  min-width: 37.5rem; /* 600px */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Heading */
h1 {
  color: #333;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  text-align: center;

}

p {
  text-align: center;
  font-size: 1.2rem; /* Adjust font size if needed */
  margin-top: 10px; /* Add some space between the <h1> and <p> */
  margin-bottom: 20px; /* Add space below the <p> */
  color: #333; /* Optional: change the color */
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

label {
  font-weight: 500;
  color: #555;
  font-size: 1rem;
}

input {
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem; /* 6px */
  font-size: 1rem;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

input:focus {
  outline: none;
  border-color: #007BFF;
  box-shadow: 0 0 0.25rem rgba(0, 123, 255, 0.5);
}

/* Button Styles */
button {
  padding: 0.75rem 1rem;
  background-color: #007BFF;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #0056b3;
}

/* Result Text */
.result {
  color: #333;
  font-size: 1.25rem;
  text-align: center;
  padding: 1rem;
  background-color: #f1f1f1;
  border-radius: 0.375rem;
  border: 1px solid #ddd;
  word-wrap: break-word;
}

@media (max-width: 40rem) { /* 640px */
  .App {
    padding: 1.5rem;
  }

  h1 {
    font-size: 1.75rem;
  }

  .result {
    font-size: 1.125rem;
  }

  button {
    font-size: 1rem;
    padding: 0.65rem;
  }
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.result-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}
